import Logger from './logger';

/**
 * This class represents a validation error thrown by HttpClient
 */
export default class ValidationError extends Error {
    constructor(body) {
        if (!body) {
            Logger.throwArgumentNullError('constructor()', 'body');
        }

        super(body.errorMessage);
        Error.captureStackTrace(this, ValidationError);
        this.type = 'ValidationError';

        this.errorCode = body.errorCode;
        this.extraData = body.extraData;
        this.sourceSystem = body.sourceSystem;
        this.errors = body.errors;
    }
}
