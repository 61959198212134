import Logger from './logger';
import fwkInjectedTypes from '../fwkInjectedTypes';

export default class UserManager {
    /**
    * Constructor
    * @param {boolean} shouldAuthorize: if set to false, the application will be considered fully public. In that case, the other parameters are optional and won't be used
    * @param {string} [userEndpoint]: service endpoint to get user from
    * @param {HttpClient} [httpClient]: httpClient instance to use to fetch data from the userEndpoint
    * @param {function} [logoutMethod]: method to call to get the current user logged out
    */
    constructor(shouldAuthorize, userEndpoint, httpClient, logoutMethod) {
        if (shouldAuthorize && !userEndpoint) {
            Logger.throwArgumentNullError('UserManager.constructor()', 'userEndpoint');
        }
        if (shouldAuthorize && !httpClient) {
            Logger.throwArgumentNullError('UserManager.constructor()', 'httpClient');
        }
        if (shouldAuthorize && !logoutMethod) {
            Logger.throwArgumentNullError('UserManager.constructor()', 'logoutMethod');
        }

        this.shouldAuthorize = shouldAuthorize;
        this.userEndpoint = userEndpoint;
        this.httpClient = httpClient;
        if (shouldAuthorize) {
            this.user = null;
            this.userPermissions = [];
        } else {
            this.user = {};
            this.userPermissions = ['*.*'];
        }
        this.logout = logoutMethod || function () { };

        // IoC
        this.injectionName = fwkInjectedTypes.userManager;
    }

    /**
     * get the current user
     */
    getUser() {
        return new Promise((resolve, reject) => {
            if (this.user) {
                resolve(this.user);
            } else {
                this.httpClient.fetch(this.userEndpoint)
                    .then(
                        u => {
                            if (!u.name || !u.functions) {
                                Logger.throwError('UserManager.getUser(): the user retrieved from the server must derive from class CosaUser');
                            }
                            this.user = u;
                            this.userPermissions = buildUserPermissions(u);
                            resolve(this.user);
                        })
                    .catch(
                        error => {
                            Logger.error(error);
                            reject(error);
                        }
                    );
            }
        });
    }

    /**
     * Check whether user is granted with a permission
     * @param {string or Array} permissions the permission or list of permission to check, When an array is provided, will return true if one of the permissions is granted to the user
     */
    checkAccess(permissions) {
        if (!permissions) {
            Logger.throwArgumentNullError('UserManager.checkAccess()', 'permissionOrAction');
        }

        const permissionsToCheck = Array.isArray(permissions) ? permissions : [permissions];

        let relatedPermissions = permissionsToCheck.reduce((aggregate, current) => aggregate.concat(_buildPermissionsRelatedTo(current)), []);
        // keep only distinct values
        relatedPermissions = [...new Set(relatedPermissions)];
        for (let i = 0; i < relatedPermissions.length; i++) {
            if (this.userPermissions.includes(relatedPermissions[i])) {
                return true;
            }
        }
        Logger.warn('Current user does not have any of the permissions [' + permissionsToCheck.join() + ']');
        return false;
    }

    /**
     * Returns an object with the CRUD permissions of the user relative to a type of entity. Each boolean property is named like 'can{operationType}'
     * Exemples: canRead, canCreate, etc...
     * @param {string} entityType name of the entity type
     */
    getPermissionsFor(entityType) {
        if (!entityType) {
            Logger.throwArgumentNullError('UserManager.getPermissionsFor()', 'entityType');
            let canCreate = this.checkAccess('Create', entityType);
            let canUpdate = this.checkAccess('Update', entityType);
            return {
                canRead: this.checkAccess('Read', entityType),
                canEdit: canCreate || canUpdate,
                canUpdate: canUpdate,
                canCreate: canCreate,
                canDelete: this.checkAccess('Delete', entityType)
            };
        }
    }
}

export function buildUserPermissions(user) {
    let permissions = user.functions.map(x => x.toLowerCase());
    if (user.permissions) {
        permissions = permissions.concat(user.permissions.map(x => x.toLowerCase()));
    }
    return permissions;
}

/*********************/
/* Private functions */
/*********************/
function _buildPermissionsRelatedTo(permissionName) {
    let perms = [];
    permissionName = permissionName.toLowerCase();
    perms.push(permissionName);

    // add undefined wildcards
    perms.push('*.*');

    // create operation
    if (permissionName.indexOf('create.') === 0) {
        perms.push(permissionName.replace('create', 'write'));
        perms.push(permissionName.replace('create', 'manage'));
        perms.push('create.*');
        perms.push('manage.*');
    }
    // update operation
    else if (permissionName.indexOf('update.') === 0) {
        perms.push(permissionName.replace('update', 'write'));
        perms.push(permissionName.replace('update', 'manage'));
        perms.push('update.*');
        perms.push('manage.*');
    }
    // edit operation
    if (permissionName.indexOf('edit.') === 0) {
        perms.push(permissionName.replace('edit', 'create'));
        perms.push(permissionName.replace('edit', 'update'));
        perms.push(permissionName.replace('edit', 'write'));
        perms.push(permissionName.replace('edit', 'manage'));
        perms.push('create.*');
        perms.push('update.*');
        perms.push('manage.*');
    }
    // delete operation
    else if (permissionName.indexOf('delete.') === 0) {
        perms.push(permissionName.replace('delete', 'manage'));
        perms.push('delete.*');
        perms.push('manage.*');
    }
    // Read operation
    else if (permissionName.indexOf('read.') === 0) {
        perms.push(permissionName.replace('read', 'manage'));
        perms.push('read.*');
        perms.push('manage.*');
    }
    else {
        let parts = permissionName.split('.');
        if (parts.length !== 2) {
            return perms;
        }

        perms.push(parts[0] + '.*');
        perms.push('*.' + parts[1]);
    }

    return perms;
}
