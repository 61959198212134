import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry } from '@fortawesome/free-solid-svg-icons';

import Landing from './Landing';
import withLabels from '../../contexts/LabelsContext';

/**
 * Landing page shown when user is not authorized to access a page, a section
 * @param {object} props No usage
 */
function forbidden(props) {
    const labels = props.labels || {};

    return <Landing>
        <h4>{labels.forbiddenTitle || 'You do not have access to this section!'}</h4>
        <FontAwesomeIcon className="landing-icon" icon={faAngry} size="6x" />
        <div className="error-hint"></div>
        <div>{labels.forbiddenHint}</div>
    </Landing>;
}

// Default export
export default withLabels(forbidden, 'errors');
