import Logger from './logger';

/**
 * This class represent an error thrown by the HttpClient
 */
export default class HttpError extends Error {
    constructor(message, content) {
        if(!message){
            Logger.throwArgumentNullError('constructor()', 'message');
        }
        if(!content){
            Logger.throwArgumentNullError('constructor()', 'content');
        }
        super(message);
        Error.captureStackTrace(this, HttpError);
        
        this.type = 'HttpError';
        this.content = content;
        
    }
}
