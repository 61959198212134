import { Component } from 'react';

import Logger from '../services/logger';

import Fatal from '../components/landing/Fatal';

/**
 * Last chance error boundary. Will catch any exception not handled by others means
 */
export default class FatalErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
        this.handleUnhandledRejectionError = _handleUnhandledRejectionError.bind(this);
    }

    componentDidMount() {
        Logger.debug('FatalErrorBoundary: registering unhandledRejection handler');
        window.addEventListener('unhandledrejection', this.handleUnhandledRejectionError);
    }

    componentWillUnmount() {
        Logger.debug('FatalErrorBoundary: unregistering unhandledRejection handler');
        window.removeEventListener('unhandledrejection', this.handleUnhandledRejectionError);
    }

    // Only used for the next version of React
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error, info) {
        Logger.error('FatalErrorBoundary: uncaught exception was thrown', error, info);
        // setting state there will be soon deprecated and replaced by getDerivedStateFromError
        this.setState({error: error});
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return Fatal({ error: this.state.error });
        }
        return this.props.children;
    }
}

function _handleUnhandledRejectionError(rejectionEvent) {
    if (rejectionEvent.cancelBubble) {
        return;
    }
    this.setState({ error: rejectionEvent.reason });
}
