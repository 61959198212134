import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindMethods } from '../helpers';
import Logger from '../services/logger';
import withLabels from '../contexts/LabelsContext';
import AlertModal from '../components/modals/AlertModal';

export class UpdateNotifier extends Component {
    constructor(props) {
        super(props);
        bindMethods(this, 'start', 'hub', 'notifyPreUpdate', 'notifyUpdate', 'ok', 'update');

        this.state = {
            notification: null,
            isConnected: false
        };

        if(!global.jQuery || !global.jQuery.hubConnection){
            Logger.warn("UpdateNotifier: it seems that signalR is not there. You should install it to benefit from deployment notifications");
            return;
        }

        this.connection = global.jQuery.hubConnection(null, { qs: { version: this.props.version } });
        this.hubs = {};
        this.hub('applicationUpdates').on('notifyPreApplicationUpdate', this.notifyPreUpdate);
        this.hub('applicationUpdates').on('notifyApplicationUpdate', this.notifyUpdate);
    }

    /********************/
    /*  SignalR         */
    /********************/
    notifyPreUpdate(message) {
        Logger.info('A new version of the application will be deployed in a few minutes: ' + message);
        this.setState({ notification: 'preupdate' });
    }

    notifyUpdate(message) {
        Logger.info('A new version of the application is available: ' + message);
        this.setState({ notification: 'update' });
    }

    start() {
        if (this.state.isConnected) {
            return new Promise(resolve => resolve());
        }

        if(!this.connection || !this.connection.disconnected){
            Logger.warn("UpdateNotifier: it seems that signalR is not there. You should install it to benefit from deployment notifications");
            return;
        }

        this.connection.disconnected(() => {
            Logger.info('SignalR disconnected.');
            setTimeout(() => {
                Logger.info('SignalR restarting connection');
                this.connection.start()
                    .done(() => {
                        this.setState({ isConnected: true });
                        Logger.info('SignalR now re-connected. Connection Id=' + this.connection.id + ', AppVersion=' + this.connection.qs.version);
                    })
                    .fail(() => { Logger.info('SignalR could not re-connect'); });
            }, 5000); // Restart connection after 5 seconds when disconnected.
        });

        return this.connection.start()
            .done(() => {
                this.setState({ isConnected: true });
                Logger.info('SignalR now connected. Connection Id=' + this.connection.id + ', AppVersion=' + this.connection.qs.version);
            })
            .fail(() => { Logger.info('SignalR could not connect'); });
    }

    hub(name) {
        let hubProxy = this.hubs[name];
        if (!hubProxy) {
            hubProxy = this.connection.createHubProxy(name);
            this.hubs[name] = hubProxy;
        }
        
        return hubProxy;
    }

    /********************/
    /* modal            */
    /********************/
    ok() {
        this.setState({ notification: null });
    }

    update() {
        window.location.reload();
    }

    /********************/
    /*  Lifecycle       */
    /********************/
    componentDidMount() {
        if (!this.state.isConnected) {
            this.start();
        }
    }

    render() {
        const labels = this.props.labels;

        if (this.state.notification === 'preupdate') {
            return <AlertModal isOpen={true} title={labels.predeployTitle} bodyText={labels.predeployMessage} actionHandler={this.ok} actionButtonLabel={labels.ok} />;
        }

        if (this.state.notification === 'update') {
            return <AlertModal isOpen={true} title={labels.reloadTitle} bodyText={labels.reloadMessage} actionHandler={this.update} actionButtonLabel={labels.reload} />;
        }

        return null;
    }
}

UpdateNotifier.propTypes = {
    // application current version number
    version: PropTypes.string.isRequired
};

export default withLabels(UpdateNotifier, 'words', 'updateNotification');
