import React from 'react';
import Logger from '../services/logger';

const ConfigurationContext = React.createContext({});
export default ConfigurationContext;

/** 
 * Retrieve configuration from server
 * @param {string} configurationUrl (mandatory): url of the server endpoint returning the react application configuration
 * 
 * @returns {object}: returns a promise of the merged configuration. Properties retrieved from server overrides existing ones on staticConfiguration
 */
export function getConfiguration(configurationUrl) {
    if (!configurationUrl) {
        Logger.throwError('You must provide a valid configuration endpoint');
    }

    return fetch(configurationUrl)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw new Error('an error occurred when retrieving configuration at ' + configurationUrl);
        })
        .catch(error => {
            Logger.error('Cannot get client configuration: ' + configurationUrl, error);
            throw error;
        });
}
