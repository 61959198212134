import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';

import withLabels from '../../contexts/LabelsContext';
import Landing from './Landing';
import AppNav from '../AppNav';

/**
 * Landing page shown user is logged out
 * @param {object} props No usage
 */
function loggedOut(props) {
    return <div>
        <AppNav loading={false} />
        <Landing>
            <h4>{props.labels.hasBeenLoggedOut}</h4>
            <FontAwesomeIcon className="landing-icon" icon={faSmile} size="6x" />
            <div>{props.labels.closeYourBrowser}</div>
        </Landing>
    </div>;
}

// Default export
export default withLabels(loggedOut, 'logout');
