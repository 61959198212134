import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import ConfigurationContext from '../contexts/ConfigurationContext';
import { bindMethods } from '../helpers';

import './AppNav.css';

/**
 * Application main navigation bar
 * - when props.ready == false && props.loadingUser == false, it assumes it is rendered before our outside your app. Thus, no navigation nor settings dropdown is shown
 * - When props.loadingUser == true, we rendre navigation settings dropdown but in "working" state. Just placeholders are shown to prepare user for information to be displayed there
 * - When props.ready == true && props.loadingUser == false, show the complete navigation bar
 * - You can provide a value for props.logoUrl if you want to override the default navbar-brand logo
 */
export default class AppNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        bindMethods(this, 'toggle');
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const brandClass = (this.props.loading ? 'pulse-opacity ' : '') + 
                           (this.props.logoUrl ? '' : 'home');

        return (
            <div className="app-nav">
                <Navbar dark expand="md" className="bg-primary">
                    <NavbarBrand href="/" className={brandClass}>
                        { this.props.logoUrl && <img alt="logo" src={this.props.logoUrl} className="h-100" /> }
                    </NavbarBrand>
                    <ConfigurationContext.Consumer>
                        {conf => <a href="/" className="app-title">{this.props.appTitle || conf.applicationName}</a> }
                    </ConfigurationContext.Consumer>
                    <NavbarToggler className="ml-auto" onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar className="pt-2 pt-md-0">
                        <Nav className="mr-auto" navbar>
                            {this.props.children}
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            {this.props.settingsDropdown}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

AppNav.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]), // the menu items
    appTitle: PropTypes.node, // the title of the application. Will use applicationName set in configuration by default,
    settingsDropdown: PropTypes.node,
    loading: PropTypes.bool,
    logoUrl: PropTypes.string, // the logo of application to display. Will use the Cds Logo by default,
};
