import React from 'react';
import ReactDOM from 'react-dom';

import CosaApp from './CosaApp';
import Logger from './services/logger';
import LocalStorage from './services/localStorage';
import addI18nLabels, { I18n } from './services/i18n';
import AAD from './services/aad';
import HttpClient from './services/httpClient';
import UserManager from './services/userManager';
import fwkLabels from './labels';
import { getConfiguration } from './contexts/ConfigurationContext';
import container from './services/ioc';
import Fatal from './components/landing/Fatal';
import AppInsights from './services/appInsights';

const jq = require('jquery');
window.jQuery = jq;
global.jQuery = jq;

require('signalr');

/**
 * Method to bootstrap your cosa application
 * @param {element} rootElement the root DOM element to mount the React app on
 * @param {object} configuration static configuration object
 * @param {string} configurationEndpoint the url of the server endpoint providing client configuration.
 * Any configuration value retrieved from server will override corresponding values in static object
 */
export default function bootstrapCosa(rootElement, configuration, configurationEndpoint) {
    if (!rootElement) {
        Logger.throwArgumentNullError('bootstrapCosa()', 'rootElement');
    }
    if (!configuration) {
        Logger.throwArgumentNullError('bootstrapCosa()', 'configuration');
    }
    if (!configuration.appLoader) {
        Logger.throwArgumentNullError('configureCosa()', 'configuration.appLoader');
    }
    if (!configuration.useLoadable) {
        Logger.throwArgumentNullError('configureCosa()', 'configuration.useLoadable');
    }

    if (!configurationEndpoint) {
        return _bootstrapCosaWithConf(configuration, rootElement);
    }

    return getConfiguration(configurationEndpoint)
        .then(conf => _bootstrapCosaWithConf(Object.assign(configuration || {}, conf), rootElement))
        .catch(error => ReactDOM.render(<Fatal error={error} />, rootElement));
}

function _bootstrapCosaWithConf(configuration, rootElement) {
    try {
        _configureCosa(configuration)
            .then((bootstrap) => {
                ReactDOM.render(<CosaApp configuration={configuration} i18n={bootstrap.i18n} aad={bootstrap.aad} />, rootElement);
            })
            .catch(error => ReactDOM.render(<Fatal error={error} />, rootElement));
    }
    catch (error) {
        ReactDOM.render(<Fatal error={error} />, rootElement);
    }
}

/**
 * Configure and returns Cosa object
 * @param {*} configuration: all the configuration for the cosa application.
 */
function _configureCosa(configuration) {
    if (!configuration.applicationName) {
        Logger.throwArgumentNullError('configureCosa()', 'configuration.applicationName');
    }
    if (!configuration.defaultLanguageCode) {
        Logger.throwArgumentNullError('configureCosa()', 'configuration.defaultLanguageCode');
    }
    if (!configuration.environment) {
        Logger.throwArgumentNullError('configureCosa()', 'configuration.environment');
    }
    const environment = configuration.environment.toLowerCase();
    if (!['dev', 'qa', 'prod'].includes(environment)) {
        Logger.throwError('configureCosa(): configuration.environment should be on of these values: dev, qa or prod');
    }

    // import styles
    var promise = import('./styles/' + environment + '/cosa-' + environment + '.css');

    // initialize storage
    const localStorage = new LocalStorage(configuration.applicationName);
    container.register(localStorage);

    // Setup I18n
    const i18n = new I18n(localStorage, configuration.defaultLanguageCode);
    container.register(i18n);
    addI18nLabels(fwkLabels);

    // Register AppInsights
    container.register(new AppInsights());

    // Setup authentication
    let aad = null;
    if (configuration.useAuthentication) {
        aad = new AAD(configuration);
        const httpClient = new HttpClient(true, aad);
        container.register(httpClient);
        container.register(new UserManager(true, configuration.userEndpoint, httpClient, aad.logout.bind(aad)));
    }
    else {
        container.register(new HttpClient(false));
        container.register(new UserManager(false));
    }

    return promise.then(() => { return { i18n: i18n, aad: aad }; });
}
