
export default class Logger {
    static debug(message, ...params) {
        console.debug(message, params);
    }
    
    static info(message, ...params) {
        console.log(message, params);
    }

    static warn(message, ...params) {
        console.warn(message, params);
    }

    static error(message, ...params) {
        console.error(message, params);
    }

    static throwError(message, ...params){
        this.error(message, params);
        throw new Error(message);
    }

    static throwArgumentNullError(method, argumentName, ...params){
        const message = method + ': parameter ' + argumentName + ' is mandatory';
        this.error(message , params);
        throw new Error(message);
    }
}
