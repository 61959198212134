import shortid from 'shortid';

/**
 * Ensure item or every items of an array have _key property
 * @param {(object|Array<object>)} objOrArray Object or Array of item to provide key
 * @param {function|string} [idGetter] Function to provide id of the item. If null or not provided, a shortid will be generated.
 */
export const addMissingKeys = (objOrArray, idGetter) => {
    return Array.isArray(objOrArray) 
            ? objOrArray.map(x => _addKey(x,idGetter)) 
            : _addKey(objOrArray,idGetter);
};

function _addKey(item, idGetter) {
    return Object.assign({ _key: _getId(item, idGetter) }, item);
}

function _getId(item, idGetter) {
    let value = null;
    if(!!idGetter) {
        switch(typeof (idGetter)) {
            case "function":
                value = idGetter(item);
                break;
            default:
                value = item[idGetter];
        }
    }

    return value || shortid.generate();
}