import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Logger from '../../services/logger';

export default function AlertModal(props) {
    if(!props.bodyText && !props.children) {
        Logger.throwArgumentNullError('AlertModal', 'You must provide either a list of children or a bodyText prop as modal body');
    }

    return <Modal isOpen={props.isOpen}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
            {props.children || props.bodyText}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={props.actionHandler}>{props.actionButtonLabel}</Button>{' '}
        </ModalFooter>
    </Modal>;
}

AlertModal.propTypes = {
    // is modal displayed or not
    isOpen: PropTypes.bool.isRequired,
    // modal title
    title: PropTypes.string.isRequired,
    // modal body text
    bodyText: PropTypes.string,
    // callback to call on button click
    actionHandler: PropTypes.func.isRequired,
    // button label
    actionButtonLabel: PropTypes.string.isRequired
};
