import fetchRetry from 'fetch-retry';

import Logger from './logger';

import ValidationError from './validationError';
import HttpError from './httpError';
import ForbiddenError from './forbiddenError';
import NotFoundError from './notFoundError';

import fwkInjectedTypes from '../fwkInjectedTypes';

export const ResponseFormat = Object.freeze({
    text: 'text',
    json: 'json'
});

/** API Class */
export default class HttpClient {
    /**
     * Api client to call server endpoints
     * @param {boolean} authenticate : true or false
     * @param {object} aad : the AAD object for authentication
     */
    constructor(authenticate, aad) {
        this.authenticate = authenticate;
        this.aad = aad;

        // IoC
        this.injectionName = fwkInjectedTypes.httpClient;
    }

    /**
     * GET
     * @param {string} url the url to call with GET
     * @param {object} queryStringParameters an object representing query string parameters. They will be serialized in url accordingly
     * @param {object} options an object with all available options:
     *              - responseFormat: the format of the expected response. Default is 'json'
     *              - fetch: provide here an object of fetch API options. Yours will override defaults set in this method implementation
     */
    get(url, queryStringParameters = null, options = {}) {
        if (!url) {
            Logger.throwArgumentNullError('get()', 'url');
        }
        const urlWithParameters = url + _formatQueryString(queryStringParameters);

        const fetchOptions = Object.assign({}, { method: 'GET' }, options.fetch);
        const responseFormat = options.responseFormat || ResponseFormat.json;

        return this.fetch(urlWithParameters, fetchOptions, responseFormat);
    }

    /**
     * POST
     * @param {string} url the url to call with POST
     * @param {object} payload the object payload to post to server
     * @param {object} queryStringParameters an object representing query string parameters. They will be serialized in url accordingly
     * @param {object} options an object with all available options:
     *              - responseFormat: the format of the expected response. Default is 'json'
     *              - fetch: provide here an object of fetch API options. Yours will override defaults set in this method implementation
     */
    post(url, payload = null, queryStringParameters = null, options = {}) {
        if (!url) {
            Logger.throwArgumentNullError('post()', 'url');
        }
        const urlWithParameters = url + _formatQueryString(queryStringParameters);

        const defaultFetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(payload)
        };
        const fetchOptions = Object.assign({}, defaultFetchOptions, options.fetch);
        const responseFormat = options.responseFormat || ResponseFormat.json;

        return this.fetch(urlWithParameters, fetchOptions, responseFormat);
    }

    /**
     * Upload a file
     * @param {string} url the url to call to upload file (POST request by default)
     * @param {file} file the file object to post to the server
     * @param {object} queryStringParameters an object representing query string parameters. They will be serialized in url accordingly
     * @param {object} options an object with all available options:
     *              - responseFormat: the format of the expected response. Default is 'text'
     *              - fetch: provide here an object of fetch API options. Yours will override defaults set in this method implementation
     */
    upload(url, file, queryStringParameters = null, options = {}) {
        if (!url) {
            Logger.throwArgumentNullError('upload()', 'url');
        }
        if (!file) {
            Logger.throwArgumentNullError('upload()', 'file');
        }
        const urlWithParameters = url + _formatQueryString(queryStringParameters);

        const formData = new FormData();
        formData.append('file', file);

        const defaultFetchOptions = {
            method: 'POST',
            body: formData
        };
        const fetchOptions = Object.assign({}, defaultFetchOptions, options.fetch);
        const responseFormat = options.responseFormat || ResponseFormat.text;

        return this.fetch(urlWithParameters, fetchOptions, responseFormat);
    }

    /**
     * PUT
     * @param {string} url the url to call with PUT
     * @param {object} payload the object payload to post to server
     * @param {object} queryStringParameters an object representing query string parameters. They will be serialized in url accordingly
     * @param {object} options an object with all available options:
     *              - responseFormat: the format of the expected response. Default is 'json'
     *              - fetch: provide here an object of fetch API options. Yours will override defaults set in this method implementation
     */
    put(url, payload = null, queryStringParameters = null, options = {}) {
        if (!url) {
            Logger.throwArgumentNullError('put()', 'url');
        }
        const urlWithParameters = url + _formatQueryString(queryStringParameters);

        const defaultFetchOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(payload)
        };
        const fetchOptions = Object.assign({}, defaultFetchOptions, options.fetch);
        const responseFormat = options.responseFormat || ResponseFormat.json;

        return this.fetch(urlWithParameters, fetchOptions, responseFormat);
    }

    /**
     * DELETE
     * @param {string} url the url to call with DELETE
     * @param {object} queryStringParameters an object representing query string parameters. They will be serialized in url accordingly
     * @param {object} options an object with all available options:
     *              - responseFormat: the format of the expected response. Default is 'text'
     *              - fetch: provide here an object of fetch API options. Yours will override defaults set in this method implementation
     */
    delete(url, queryStringParameters = null, options = {}) {
        if (!url) {
            Logger.throwArgumentNullError('delete()', 'url');
        }
        const urlWithParameters = url + _formatQueryString(queryStringParameters);

        const fetchOptions = Object.assign({}, { method: 'DELETE' }, options.fetch);
        const responseFormat = options.responseFormat || ResponseFormat.text;

        return this.fetch(urlWithParameters, fetchOptions, responseFormat);
    }


    /**
     * Agnostic fetch method
     * @param {string} url the url to call
     * @param {object} options the options object for the fetch api
     * @param {responseFormat} ResponseFormat the format of the expected response, either json or text
     */
    fetch(url, options = {}, responseFormat = ResponseFormat.json) {
        if (!url) {
            Logger.throwArgumentNullError('fetch()', 'url');
        }

        if (this.authenticate) {
            return this.aad.getToken()
                .then(token => {
                    Logger.debug("httpClient: token has been retrieved successfully. About to call " + url + " with options: ", options);
                    const o = _addAuthHeaders(token, options);
                    return fetchRetry(url, o);
                }
                ).then(resp => _handleResponse(resp, url, responseFormat));
        }

        return fetchRetry(url, options).then(resp => _handleResponse(resp, url, responseFormat));
    }
}

/*******************/
/* Private methods */
/*******************/
function _addAuthHeaders(token, options) {
    const o = options || {};
    if (!o.headers)
        o.headers = {};
    o.headers.Authorization = 'Bearer ' + token;
    return o;
}

function _handleResponse(resp, url, responseFormat) {
    // OK
    if (resp.ok) {
        Logger.debug('httpClient: server returned OK on ' + url + '. About to parse the ' + responseFormat + ' response.');

        if (resp.headers.get('content-length') === '0') {
            return;
        }

        return resp[responseFormat]()
            .then(result => {
                Logger.debug('httpClient: ' + responseFormat + ' reponse received from ' + url + ' has been parsed successfully.');
                return result;
            });
    }
    // Validation error
    else if (resp.status === 412) {
        Logger.error("httpClient: server returned validation(s) error on " + url + ".", resp);
        return resp.json().then(result => {
            Logger.debug('httpClient: validation errors thrown by ' + url + ' have been parsed successfully.');
            throw new ValidationError(result);
        });
    }
    // Forbidden error
    else if (resp.status === 403) {
        Logger.error("httpClient: server returned forbidden error on " + url + ".", resp);
        return _readResponse(resp, url)
            .then(body => {
                throw new ForbiddenError(body.exceptionMessage || body.message || 'You are not allowed to access' + url, body);
            });
    }
    // not found
    else if (resp.status === 404) {
        Logger.error("httpClient: server returned not found error on " + url + ".", resp);
        throw new NotFoundError('Page or object ' + url + ' not found', resp);
    }
    // unknown error
    Logger.debug("httpClient: server returned error '" + resp.statusText + " (" + resp.status + ")' on " + url + ".", resp);

    return _readResponse(resp, url)
        .then(body => {
            throw new HttpError(body.exceptionMessage || body.message || 'an error occurred when calling ' + url, body);
        });
}

function _readResponse(resp, url) {
    return resp.text()
        .then(textBody => {
            let json = null;
            try {
                json = JSON.parse(textBody);
            }
            catch (err) {
                Logger.debug('httpClient: cannot parse error thrown by ' + url + ' as json', err);
            }
            return json || textBody;
        });
}

function _formatQueryString(parameters) {
    if (!parameters) {
        return '';
    }

    var queryString = '';
    for (var propt in parameters) {
        if (parameters.hasOwnProperty(propt)) {
            if (parameters[propt] !== null && parameters[propt] !== undefined) {
                queryString = queryString + propt + '=' + encodeURIComponent(parameters[propt]) + '&';
            }
        }
    }
    return queryString === '' ? '' : '?' + queryString.substr(0, queryString.length - 1);
}
