import Logger from '../services/logger';

/**
 * Build a user-friendly name for high order components.
 * This will help debugging your application, by having a meaningfull name displayed into the React components tree
 * @param {string} hocName name of the high order component
 * @param  {class or func} WrappedComponent The class or func definition of the component wrapped by the HOC
 */
export default function getHocDisplayName(hocName, WrappedComponent) {
    if (!hocName) { Logger.throwArgumentNullError('getHocDisplayName', 'hocName'); }
    if (!WrappedComponent) { Logger.throwArgumentNullError('getHocDisplayName', 'WrappedComponent'); }

    const compName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    return `${hocName}(${compName})`;
}
