import React, { Component } from 'react';
import { NavItem } from 'reactstrap';

import AppNav from '../components/AppNav';

import './BootAppNav.css';

export default class BootAppNav extends Component {
    render() {
        return (
            <AppNav loading={true}>
                <NavItem className="fake">_</NavItem>
                <NavItem className="fake">_</NavItem>
                <NavItem className="fake">_</NavItem>
                <NavItem className="fake">_</NavItem>
            </AppNav>
        );
    }
}
