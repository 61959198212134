import Logger from './logger';

/**
 * This class represents a not found 404 http error
 */
export default class NotFoundError extends Error {
    constructor(message, content) {
        if(!message){
            Logger.throwArgumentNullError('constructor()', 'message');
        }
        if(!content){
            Logger.throwArgumentNullError('constructor()', 'content');
        }
        super(message);
        Error.captureStackTrace(this, NotFoundError);
        
        this.type = 'NotFoundError';
        this.content = content;
    }
} 
