import Logger from './logger';

/**
 * This class represents an unauthorized 403 http error
 */
export default class ForbiddenError extends Error {
    constructor(message, content) {
        if(!message){
            Logger.throwArgumentNullError('constructor()', 'message');
        }
        if(!content){
            Logger.throwArgumentNullError('constructor()', 'content');
        }
        super(message);
        Error.captureStackTrace(this, ForbiddenError);
        
        this.type = 'ForbiddenError';
        this.content = content;
    }
}
