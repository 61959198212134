
/**
 * Bind functions to an object or class
 * Use in React components to bind functions passed as callbacks or props to the component they're declared in 
 * @param {object} obj object to bind functions to
 * @param  {...string} funcs list of function names to bind
 */
export default function bindMethods(obj, ...funcs) {
    funcs.forEach(f => {
        obj[f] = obj[f].bind(obj);
    });
}
