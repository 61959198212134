import React from 'react';

import './Landing.css';

/**
 * Landing pages main frame
 */
export default props => {
    return (
        <div className="landing">
            <div className="landing-card">
                <div className="landing-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
};
