import { PublicClientApplication } from "@azure/msal-browser";

import Logger from './logger';

// AAD
export default class AAD {
    constructor({tenantId, applicationId, postLogoutRedirectUri}) {
        if(!tenantId){
            Logger.throwError('tenantId is mandatory');
        }
        if(!applicationId){
            Logger.throwError('applicationId is mandatory');
        }
        if(!postLogoutRedirectUri){
            Logger.throwError('postLogoutRedirectUri is mandatory');
        }

        const msalConfig = {
            auth: {
                clientId: applicationId,
                authority: `https://login.microsoftonline.com/${tenantId}`,
                postLogoutRedirectUri: postLogoutRedirectUri || `${window.location.origin}/out`,
                redirectUri: `${window.location.origin}/`
            },
            cache: {
                cacheLocation: "localStorage"
            }
        };
        
        this.applicationId = applicationId;
        this.authContext = new PublicClientApplication(msalConfig);
    }

    getToken() {
        var accounts = this.authContext.getAllAccounts();
        if (accounts.length === 0 || !accounts[0]) {
            throw new Error("No active account");
        }
        
        const tokenRequest = {
            account: accounts[0],
            scopes: [`${this.applicationId}/.default`]
        };
        
        return this.authContext.acquireTokenSilent(tokenRequest)
        .then(tokenResponse => tokenResponse.accessToken)
        .catch(_ => {
            return this.authContext.acquireTokenPopup(tokenRequest)
            .then(tokenResponse => tokenResponse.accessToken);
        });
    }

    authenticate(successCallback, failCallback) {
        var accounts = this.authContext.getAllAccounts();
        if(accounts.length > 0){
            successCallback();
        } else {
            failCallback();
        }
    }

    login() {
        this.authContext.handleRedirectPromise().then(_ => {
            var account = this.authContext.getActiveAccount();
            if(!account){
                this.authContext.loginRedirect();
            }
        });
    }

    logout() {
        this.authContext.logoutRedirect();
    }
}


// USER
let _user;

export function getCurrentUser(api, url) {
    return new Promise((resolve, reject) => {
        if (_user === undefined) {
            api.fetch(url)
                .then(
                    resp => resp.json(),
                    error => reject(error)
                )
                .then(
                    user => {
                        _user = user;
                        resolve(_user);
                    },
                    error => reject(error)
                );
        } else {
            resolve(_user);
        }
    });
}