import fwkInjectedTypes from "../fwkInjectedTypes";

export default class AppInsights {
    constructor() {
        // IoC
        this.injectionName = fwkInjectedTypes.appInsights;
    }

    init(config, versionNo) {
        try {
            const appInsightModule = require("@microsoft/applicationinsights-web");
            this.appInsights = new appInsightModule.ApplicationInsights(config);
        }
        catch (e) {
            // do not load appInsight
            this.appInsights = null;
            return;
        }

        this.appInsights.loadAppInsights();

        this.appInsights.addTelemetryInitializer((envelope) => {
            // Ignore signalr
            if (envelope.baseData && envelope.baseData.target && envelope.baseData.target.includes('/signalr/')) {
                return false;
            }
            
            // Track application version
            if (envelope.baseData) {
                const telemetryItem = envelope.baseData;
                telemetryItem.properties = telemetryItem.properties || {};
                telemetryItem.properties['ApplicationVersion'] = versionNo;
                
                if(telemetryItem.responseCode === 412){
                    envelope.baseData.success = true;
                }
            }
        });
    }

    trackEvent(event, customProperties) {
        if (this.appInsights) {
            this.appInsights.trackEvent(event, customProperties);
        }
    }

    setAuthenticatedUserContext(authenticatedUserId) {
        if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(authenticatedUserId);
        }
    }
}
