import Logger from './logger';
import fwkInjectedTypes from '../fwkInjectedTypes';

/**
 * Local storage wrapper (will prefix keys with application name)
 */
export default class LocalStorage{
    /**
     * Constructor
     * @param {string} applicationName your application name
     */
    constructor(applicationName){
        if(!applicationName){
            Logger.throwArgumentNullError('ctor()', applicationName);
        }

        this.prefix = applicationName;
        this.expirationSuffix = '.expiration';

        // IoC
        this.injectionName = fwkInjectedTypes.localStorage;
    }

    /**
     * Retrieve a value from local storage
     * @param {string} key key of the storage element to retrieve
     */
    getItem(key){
        if(!key){
            Logger.throwArgumentNullError('getItem()', key);
        }
        const value = localStorage.getItem(`${this.prefix}:${key}`);
        const expiration = localStorage.getItem(`${this.prefix}:${key}${this.expirationSuffix}`);
        if (expiration && new Date(parseInt(expiration)).getTime() < Date.now())
            return null;
        return value;
    }

    /**
     * Store a value into local storage
     * @param {string} key key of the storage element to retrieve
     * @param {object} value the value to store
     * @param {number} expiration the delay before expiration (in ms)
     */
    setItem(key, value, expiration = null){
        if(!key){
            Logger.throwArgumentNullError('setItem()', key);
        }
        localStorage.setItem(`${this.prefix}:${key}`, value);
        localStorage.setItem(`${this.prefix}:${key}${this.expirationSuffix}`, expiration ? Date.now() + expiration : null);
    }
}
