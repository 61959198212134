export default {
    logout: {
        link: { en: 'Logout', fr: 'Deconnexion' },
        linkTitle: { en: 'Log out of my account', fr: 'Me deconnecter de mon compte' },
        hasBeenLoggedOut: { en: 'You logged out successfully', fr: 'Vous avez été correctement déconnecté' },
        closeYourBrowser: { en: 'You can now close your browser', fr: 'Vous pouvez maintenant fermer votre navigateur' }
    },
    splash: {
        title: { en: 'Loading...', fr: 'Chargement...' },
        error: { en: 'Error loading module!', fr: 'Impossible de charger le module!' }
    },
    words: {
        retry: { en: 'Retry', fr: 'Réessayer' },
        all: { en: 'All', fr: 'Tous' },
        name: { en: 'Name', fr: 'Nom' },
        save: { en: 'Save', fr: 'Sauvegarder' },
        cancel: { en: 'Cancel', fr: 'Annuler' },
        add: { en: 'Add', fr: 'Ajouter' },
        delete: { en: 'Delete', fr: 'Supprimer' },
        search: { en: 'Search', fr: 'Rechercher' },
        yes: { en: 'Yes', fr: 'Oui' },
        no: { en: 'No', fr: 'Non' },
        ok: { en: 'OK', fr: 'OK' },
        back: { en: 'Back', fr: 'Retour' }
    },
    languages: {
        fr: { en: 'French', fr: 'Français' },
        en: { en: 'English', fr: 'Anglais' }
    },
    errors: {
        fatalTitle: { en: 'A critical error occurred!', fr: 'Une erreur fatale s\'est produite' },
        forbiddenTitle: { en: 'You do not have access to this section!', fr: 'Vous n\'avez pas accès à cette section!' },
        forbiddenHint: { en: 'You might ask your super user to grant you the required access level.', fr: 'Vous pouvez éventuellement demander à votre super utilisateur les accès requis.' },
        notFoundTitle: { en: 'Page not found!', fr: 'Page non trouvée!' },
        notFoundMessage: { en: 'This is probably not the place you want to be right now...', fr: 'Ce n\'est sans doute pas la page que vous désirez...' },
        header: { en: 'Please fix the following errors and retry again.', fr: 'Veuillez adresser les erreurs suivantes et re-essayez.' },
        internalServerError: { en: 'An error occurred while handling your request', fr: "Une erreur s'est produite lors du traitement de votre demande" },
        invalidEntry: { en: 'Invalid entry', fr: 'Valeur invalide' },
        required: { en: 'This field is required', fr: 'Ce champ est requis' },
        isNotEmail: { en: 'Should be a valid email address', fr: 'Doit etre une adresse courriel valide' },
        isNotNumeric: { en: 'Should be numeric', fr: 'Doit etre une valeur numerique' },
        isNotInt: { en: 'Should be an integer value', fr: 'Doit etre un nombre entier' },
        isNotAlpha: { en: 'Should contain letters only', fr: 'Doit contenir des lettres uniquement' },
        isNotAlphanumeric: { en: 'Should be alphanumeric', fr: 'Doit etre alpha-numerique' },
        isNotIn: { en: 'Value is not authorized', fr: 'Valeur non authorisee' },
        doesNotEqual: { en: 'Invalid entry', fr: 'Valeur invalide' },
        doesNotContain: { en: 'Invalid entry', fr: 'Valeur invalide' },
        doesNotMatch: { en: 'Invalid format', fr: 'Format invalide' },
        isShorter: { en: 'The entry is too short', fr: 'La valeur saisie trop courte' },
        isLonger: { en: 'This entry too long', fr: 'La valeur saisie trop longue' },
        isLesser: { en: 'Value is lesser than required', fr: 'La valeur est inferieure au minimum requis' },
        greaterThan: { en: 'Value is lesser than required', fr: 'La valeur est inferieure au minimum requis' },
        isGreater: { en: 'Value is greater than required', fr: 'La valeur est superieur au maximum requis' }
    },
    widgets: {
        autoComplete: {
            placeholder: { en: 'Search...', fr: 'Rechercher...' },
            dismiss: { en: 'Dismiss selection', fr: 'Annuler la sélection' },
            addSuggestion: { en: 'Add and use ', fr: 'Ajouter et utiliser ' },
            errorWhileSearching: { en: 'Cannot retrieve suggestions for you', fr: 'La récupération de suggestions a échouée' },
            noSuggestions: { en: 'No suggestions found', fr: 'Aucune suggestion trouvée' }
        },
        referenceDropdown: {
            selectItem: { en: 'Select an item....', fr: 'Sélectionner un élément...' }
        },
        modals: {
            ConfirmDeletionTitle: { en: 'Confirme deletion', fr: 'Confirmer la suppression' },
            confirmDeletionDefaultText: {
                en: 'Are you sure you want to delete this entity?\r\nThis action can\'t be undone',
                fr: 'Êtes-vous sûr de vouloir supprimer cette entité?\r\nCette action est irréversible'
            },
            confirmDeletionGenericText: {
                en: 'Are you sure you want to delete this %1?\r\nThis action can\'t be undone',
                fr: 'Êtes-vous sûr de vouloir supprimer ce(tte) %1?\r\nCette action est irréversible'
            }
        }
    },
    updateNotification: {
        reload: { en: 'Reload', fr: 'Recharger' },
        reloadMessage: {
            en: 'A new version of the application is available and you need to reload the application by clicking \'Reload\'.',
            fr: 'Une nouvelle version de l\'application est disponible. Vous devez rafraîchir la page en cliquant sur le bouton \'Recharger\''
        },
        reloadTitle: { en: 'New version available', fr: 'Nouvelle version disponible' },
        predeployMessage: {
            en: 'A new version of the application will be deployed in few minutes. Please save your work now and stop changing data until further notice.',
            fr: 'Une nouvelle version de l\'application sera bientôt déployée. SVP, sauvez vos modifications et attendez les instructions avant de réutiliser l\'application.'
        },
        predeployTitle: { en: 'New version soon deployed', fr: 'Nouvelle version bientôt déployée' }
    },
    about: {
        about: { en: 'About', fr: 'A propos' },
        app: { en: 'Application', fr: 'Application' },
        version: { en: 'Version', fr: 'Version' }
    }
};
