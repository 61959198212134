import React from 'react';
import PropTypes from 'prop-types';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDizzy } from '@fortawesome/free-solid-svg-icons';

import Landing from './Landing';
import withLabels from '../../contexts/LabelsContext';

import './Errors.css';

/**
 * Landing page shown when a critical, uncaught exception was thrown
 * @param {object} props See propTypes for detail
 */
function fatal(props) {
    const labels = props.labels || {};
    const errorDetail = props.error ?
        <div className="error-hint"><em>{props.error.message || props.error}</em></div> :
        '';
    return <Landing>
        <h4>{labels.fatalTitle || 'A critical error occurred!'}</h4>
        <FontAwesomeIcon className="landing-icon" icon={faDizzy} size="6x" />
        <div>{errorDetail}</div>
    </Landing>;
}

fatal.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) // The error thrown. Will be shown as best as possible (using directly the string, or its 'message' property)
};

// Default export
export default withLabels(fatal, 'errors');

