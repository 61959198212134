import Logger from './logger';

class Container {
    constructor() {
        this.dependencies = {};
    }

    register(dependency, name, override = false) {
        if (!dependency) {
            Logger.throwArgumentNullError('Container.register()', 'dependency');
        }

        name = name || dependency.injectionName || null;
        if (!name) {
            Logger.throwError('Container.register(): the dependency you are trying to register has no injection name. Please provide this name as parameter or add a property "injectionName" on the dependency itself to register the dependency under this key', dependency);
        }

        var existingDependency = this.dependencies[name];
        if (existingDependency && !override) {
            Logger.throwError(`Container.register(): a dependency with the same name "${name}" is already register. Set override to true to override the existing registered object.'`, dependency);
        }

        this.dependencies[name] = dependency;
        Logger.debug(`Container.register(): add new dependency with name "${name}".`, dependency);
    }

    resolve(name, isOptional) {
        if (!name) {
            Logger.throwArgumentNullError('Container.resolve()', 'name');
        }

        if (this.dependencies[name]) {
            return this.dependencies[name];
        }

        if (isOptional) {
            return null;
        }

        Logger.throwError(`Container.resolve(): There is no dependency registered under the name "${name}".`);
    }
}

const container = new Container();
export default container;
