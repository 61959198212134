import React from 'react';
import Logger from '../services/logger';

export const LabelsContext = React.createContext({});

export default function withLabels(Component, ...subsets) {
    return function LabelledComponent(props) {
        return (
            <LabelsContext.Consumer>
                {(labels) => {
                    const componentLabels = _buildLabelsSet(labels, ...subsets);
                    return <Component {...props} labels={{ ...componentLabels, ...(props.labels || {}) }} />;
                }}
            </LabelsContext.Consumer>
        );
    };
}

function _buildLabelsSet(labels, ...subsets) {
    if (!subsets || subsets.length === 0) {
        return labels;
    }

    var result = {};
    subsets.forEach(subset => {
        if (typeof subset === 'string') {
            result = Object.assign(result, labels[subset]);
            return;
        }

        if ({}.toString.call(subset) === '[object Function]') { // when function
            result = Object.assign(result, subset(labels));
            return;
        }

        Logger.throwError('_buildLabelsSet(): each subset parameter must either be the name of a node on labels or a function returning a subset of labels object');
    });
    return result;
}

export function composeLabel(label, ...params) {
    if (!label) {
        Logger.throwArgumentNullError('composeLabel()', 'label');
    }

    var result = label;
    params.forEach((replacement, idx) => {
        result = result.replace('%' + (idx + 1), replacement);
    });
    return result;
}
