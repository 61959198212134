import update from 'immutability-helper';
/** 
 * Handle form changes using default form implementation (don't forget to bind this on component)
 * @param {SyntheticEvent} event The UI event to handle
 * @param {function(state)|string} [stateGetter] Optional function to retrieve state (which will receive this.state as parameter). If a string is provided, this.state[stateGetter] will be the retrieved (Default will be this.state)
 * @param {boolean} [performUpdate=true] Whether or not prevent default behavior of event (default: true)
 * @param {boolean} [preventDefault=false] Whether or not prevent default behavior of event (default: false)
 */
export const genericChangeHandler = function (event, stateGetter, performUpdate = true, preventDefault = false) {
    if (preventDefault) {
        !!event.isDefaultPrevented && !event.isDefaultPrevented() && !!event.preventDefault && event.preventDefault();
    }

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const stateProperty = (stateGetter && typeof stateGetter === 'string') ? stateGetter : null;
    const stateGetterFunc = (stateGetter && {}.toString.call(stateGetter) === '[object Function]') ? stateGetter : null;
    const current = (stateProperty && this.state[stateProperty]) || (stateGetterFunc && stateGetter(this.state)) || this.state;

    let typedValue = value;
    switch (typeof current[name]) {
        case 'number':
            const parsed = parseFloat(value, 10);
            typedValue = !isNaN(parsed) ? parsed : value;
            break;
        default:
            typedValue = value;
    }

    if (!performUpdate) {
        return { typedValue, name };
    }
    if (!stateGetter && !stateGetterFunc) {
        return this.setState({ [name]: typedValue });
    }
    if (!!stateGetterFunc) {
        return this.setState(prevState => update(prevState, { $apply: (x) => stateGetterFunc(x)[name] = typedValue }));
    }
    if (!!stateProperty) {
        return this.setState(prevState => update(prevState, { [stateProperty]: { [name]: { $set :typedValue } } }));
    }
};
