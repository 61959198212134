import { Component } from 'react';

import Logger from '../services/logger';

import Forbidden from '../components/landing/Forbidden';

/**
 * Unauthorized error boundary. Will catch any 403 exception thrown by the server, or any uncaugth ForbiddenError thrown by the application
 */
export default class ForbiddenErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
        this.handleUnhandledRejectionError = _handleUnhandledRejectionError.bind(this);
    }
    componentDidMount() {
        Logger.debug('ForbiddenErrorBoundary: registering unhandledRejection handler');
        window.addEventListener('unhandledrejection', this.handleUnhandledRejectionError);
    }

    componentWillUnmount() {
        Logger.debug('ForbiddenErrorBoundary: unregistering unhandledRejection handler');
        window.removeEventListener('unhandledrejection', this.handleUnhandledRejectionError);
    }

    // Only used for the next version of React
    static getDerivedStateFromError(error) {
        if (error.type === 'ForbiddenError') {
            // Update state so the next render will show the fallback UI.
            return { error: error };
        }
    }

    componentDidCatch(error, info) {
        if (error.type === 'ForbiddenError') {
            Logger.error('ForbiddenErrorBoundary: forbidden exception was thrown', error, info);
            // setting state there will be soon deprecated and replaced by getDerivedStateFromError
            this.setState({error: error});
        }
        else {
            throw error;
        }
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI           
            return Forbidden({ error: this.state.error });
        }
        return this.props.children;
    }

}

function _handleUnhandledRejectionError(rejectionEvent) {
    if (rejectionEvent.cancelBubble) {
        return;
    }
    if (rejectionEvent.reason.type === 'ForbiddenError') {
        this.setState({ error: rejectionEvent.reason });
        rejectionEvent.stopPropagation();
    }
}
