import Logger from './logger';
import container from '../services/ioc';
import fwkInjectedTypes from '../fwkInjectedTypes';

const _storageLanguageCodeKey = 'language';

export class I18n {
    /**
     * Constructor
     * @param {LocalStorage} localStorage instance of local storage to store the current language code
     * @param {string} defaultLanguageCode code of the default language of the application
     */
    constructor(localStorage, defaultLanguageCode) {
        if (!localStorage) {
            Logger.throwArgumentNullError('constructor()', 'localStorage');
        }
        if (!defaultLanguageCode) {
            Logger.throwArgumentNullError('constructor()', 'defaultLanguageCode');
        }

        this.localStorage = localStorage;
        this.defaultLanguageCode = defaultLanguageCode.toLowerCase();
        this.labels = {};

        this.languageCode = localStorage.getItem(_storageLanguageCodeKey);
        if (!this.languageCode) {
            localStorage.setItem(_storageLanguageCodeKey, defaultLanguageCode);
            this.languageCode = defaultLanguageCode.toLowerCase();
        }

        // IoC
        this.injectionName = fwkInjectedTypes.i18n;
    }

    changeLanguage(newLanguageCode) {
        if (!newLanguageCode) {
            Logger.throwArgumentNullError('changeLanguage()', 'newLanguageCode');
        }
        if (newLanguageCode === this.languageCode) {
            return;
        }
        this.localStorage.setItem(_storageLanguageCodeKey, newLanguageCode);
        window.location.reload();
    }
}

/*****************
* Exported methods
*****************/
/**
* Add labels tree merged into the existing ones. Latest labels added will override older ones.
* If a translation is missing, the label for {defaultLanguageCode} will be used instead
* @param {object} labelsSection: a complex object of labels
*/
const addLabels = function (labelsSection) {
    if (!labelsSection) { return; }

    const i18n = container.resolve(fwkInjectedTypes.i18n);

    for (var property in labelsSection) {
        if (!labelsSection.hasOwnProperty(property)) {
            continue;
        }
        var value = labelsSection[property];
        if (typeof value === 'object' && value !== null) {
            _mergeLabels.call(i18n, i18n.labels, labelsSection[property], property);
        }
    }
};
export default addLabels;

/*****************
* Private methods
*****************/
function _mergeLabels(labels, newLabels, sectionName) {
    for (var property in newLabels) {
        if (!newLabels.hasOwnProperty(property)) {
            continue;
        }

        var value = newLabels[property];
        // if value is a string => include it in labels when language match
        if ((typeof value === 'string' || value instanceof String) && property.toLowerCase() === this.languageCode) {
            labels[sectionName] = newLabels[property];
        }
        // if value is a string and language code equals default one, include it in label (whithout overriding prior value)
        else if ((typeof value === 'string' || value instanceof String) && property.toLowerCase() === this.defaultLanguageCode) {
            if (!labels[sectionName]) {
                labels[sectionName] = newLabels[property];
            }
        }

        // if value is an object => recurse
        else if (typeof value === 'object' && value !== null) {
            if (!labels[sectionName]) {
                labels[sectionName] = {};
            }
            _mergeLabels.call(this, labels[sectionName], newLabels[property], property);
        }
    }
}
