import React, { Component } from 'react';

import { Router, Route, Switch, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';

import Logger from './services/logger';
import { LabelsContext } from './contexts/LabelsContext';
import ConfigurationContext from './contexts/ConfigurationContext';
import container from './services/ioc';
import LandingOut from './components/landing/LoggedOut';
import BootAppNav from './components/BootAppNav';
import FatalErrorBoundary from './components/FatalErrorBoundary';
import ForbiddenErrorBoundary from './components/ForbiddenErrorBoundary';
import fwkInjectedTypes from './fwkInjectedTypes';
import UpdateNotifier from './signalr/UpdateNotifier';
import ScrollToTop from './components/ScrollToTop';

import './CosaApp.css';

/**
 * Cosa App class
 */
export default class CosaApp extends Component {
    constructor(props) {
        super(props);

        const configuration = props.configuration;

        this.history = createBrowserHistory();
        this.appInsights = container.resolve(fwkInjectedTypes.appInsights, true);
        
        if (configuration && configuration.appInsightsConnectionString && this.appInsights) {
            let config = { 
                config: { 
                    connectionString: configuration.appInsightsConnectionString,
                    autoTrackPageVisitTime: false,
                    disableFetchTracking: false,
                    enableAutoRouteTracking: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    enableCorsCorrelation: true,
                    maxAjaxCallsPerView: -1
                }
            };
            this.appInsights.init(config, configuration.versionNo);
        }

        if (!configuration) {
            Logger.throwError('constructor(): you must call configureCosa before rendering the CosaApp container');
        }
        if (configuration.useAuthentication && !configuration.userEndpoint) {
            Logger.throwError('constructor(): you must specify a userEndpoint in your configuration to retrieve user information from server');
        }
        if (!configuration.appLoader) {
            Logger.throwArgumentNullError('constructor()', 'configuration.appLoader');
        }

        // State
        this.state = { ready: !configuration.useAuthentication };

        // services
        this.aad = this.props.aad;
        this.userManager = container.resolve(fwkInjectedTypes.userManager);
    }

    /**
     * Component did mount
     */
    async componentDidMount() {
        if (this.props.configuration.useAuthentication && !this.state.ready) {
            await this.aad.authContext.handleRedirectPromise();
            this.aad.authenticate(
                // if authenticated
                () => {
                    this.userManager.getUser()
                        .then((user) => {
                            this.appInsights && this.appInsights.setAuthenticatedUserContext(user.upn);
                            this.setState({ ready: true });
                        });
                },
                // otherwise, log in
                () => { if (this.history.location.pathname !== '/out') this.aad.login(); }
            );
        }
    }

    /**
     * Render
     */
    render() {
        const { configuration, i18n } = this.props;

        let LoadingPage = configuration.loadingPage;
        if (!LoadingPage) {
            LoadingPage = BootAppNav;
        }

        let content;
        if (this.state.ready) {
            if (configuration.useLoadable) {
                const App = withRouter(Loadable({
                    loader: configuration.appLoader,
                    loading: LoadingPage
                }));

                content = <App />;
            } else {
                content = configuration.appLoader();
            }
        } else {
            content = <LoadingPage />;
        }

        return (
            <ConfigurationContext.Provider value={configuration}>
                <LabelsContext.Provider value={i18n.labels}>
                    <FatalErrorBoundary>
                        <ForbiddenErrorBoundary>
                            <Router history={this.history}>
                                <Switch>
                                    <Route exact path="/out" component={LandingOut} />
                                    <React.Fragment>
                                        <ScrollToTop />
                                        {content}
                                    </React.Fragment>
                                </Switch>
                            </Router>
                            <UpdateNotifier version={configuration.versionNo} />
                        </ForbiddenErrorBoundary>
                    </FatalErrorBoundary>
                </LabelsContext.Provider>
            </ConfigurationContext.Provider>
        );
    }
}