import bootstrapCosa from './fwk/bootstrapCosa';

const rootElement = document.getElementById('root');
const configuration = {
    appLoader: () => import('./App.jsx'),
    useAuthentication: true, // default
    userEndpoint: '/api/user',
    useLoadable: true,
    availableLanguages: ['fr', 'en']
};

bootstrapCosa(rootElement, configuration, '/api/configuration');
